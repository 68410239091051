import React, { Component } from 'react'
import axios from 'axios'
import { Redirect } from 'react-router-dom';
import './photoupload.scss'
class PhotoUpload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: '',
      image: '',
      body: '',
      redirect: false,
      file: null
    }
  }


  
  handleSubmit = event => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('title', this.state.title);
    formData.append('body', this.state.body);
    formData.append('image', this.state.image);    
    fetch('/api/v1/posts', {
      method: 'POST',
      body: formData
    })
    .then(response => {
      console.log('POST RESPONSE', response)
      this.setState({redirect: true})
    })
    .catch(error=>console.log(error));
  }

  handleTitleChange = (e) => {
    this.setState({title: e.target.value});
  }

  handleFileChange = (e) => {
    const imageFile = e.target.files[0];
    this.setState({image: imageFile});
    this.setState({file: URL.createObjectURL(imageFile)})
  }

  render() {
    return (
      this.state.redirect ? 
        <Redirect to='/'/>
      :  
      <div className="Photos-Upload">
        <input 
          className="taskInput" type="text" 
          placeholder="Add a task" maxLength="50"
          value={this.state.title} 
          onChange={this.handleTitleChange} 
        />  
        <input type="file"
          id="avatar" name="avatar"
          accept="image/png, image/jpeg"
          multiple={false}
          onChange={this.handleFileChange}
        />
        
        <div className="preview">
          { this.state.file ?
            <img src={this.state.file} />
            : null   
          }
          </div>
        <button onClick={this.handleSubmit}> SUBMIT </button>
      </div>        
    )
  }
}

export default PhotoUpload