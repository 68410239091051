import React, { Component } from 'react'
import axios from 'axios'
//import {ImageList, ImageListItem} from '@material/image-list';
import {ImageList, ImageListItem} from '@material-ui/core/';

import { withStyles } from '@material-ui/core/styles';
import Masonry from 'react-masonry-css'
import './photosindex.scss'

const styles = {
  root: {
    width: "100%",
    height: "100%",
  }
}
function srcset(image, size, rows = 1, cols = 1) {
  return `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format 1x,
  ${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format&dpr=2 2x`;
}
class PhotosIndex extends Component {
  constructor(props) {
    super(props)
    this.state = {
      photos: [],
      loading: false
    }

  }

  getPics() {
    this.setState({loading: true})
    console.log('getting pics',)
    axios.get('/api/v1/posts')
    .then(response => {
      console.log('done loading', response.data);
      //response.data.sort((a, b) => (a.positon > b.position) ? 1 : -1)
      
      
      var photos = response.data;
      photos.map(el => {
        if (!el.position) {
          el.position = photos.findIndex(x => x === el);
        }
      })
      
      
      
      this.setState({photos})
/*       var orderedState = this.state.photos.map((el) => {
        el.position = el.index
      })
      this.setState({photos: orderedState }) */

      this.setState({loading: false})
    })
    .catch(error => console.log(error))
  }

  deletePic(id) {
    axios.delete(`/api/v1/posts/${id}`)
    .then(res => {
      console.log('done deleting', res)
      document.location.reload();
    })
    .catch(err => console.log(err))
  }

  componentDidMount() {
    this.getPics()
    // this.setState({photos: this.state.photos.sort((a, b) => (a.position - b.position))})

  }  

  shouldComponentUpdate(prevState) {
    if (prevState != this.state) {
     return true
    }
  }

  renderEditableActions(id) {
    return <div>
      <button onClick={this.deletePic}>DELETE</button>
    </div>
  }


  editOrder(id, operator) {
    var photos = this.state.photos;
    var editedPhotos = photos.map(a => {return {...a}})
    var currentPosition = editedPhotos.find(a => a.id === id).position
    if (operator == '+') {
      editedPhotos.find(a => a.id === id).position = currentPosition + 1 
    }
    if (operator == '-') {
      editedPhotos.find(a => a.id === id).position = currentPosition - 1 
    }    
    editedPhotos.sort((a, b) => (a.position - b.position))
    
    this.setState({photos: editedPhotos})
  }

  

  editCol(id, operator, gridEl) {
    var photos = this.state.photos;
    var editedPhotos = photos.map(a => {return {...a}})
    if (gridEl == 'cols' ) {
      var currentCols = editedPhotos.find(a => a.id === id).cols
      if (operator == '+') {
        editedPhotos.find(a => a.id === id).cols = currentCols + 1 
      }
      else if (operator == '-') {
        editedPhotos.find(a => a.id === id).cols = currentCols - 1 
      }
    }
    if (gridEl == 'rows' ) {
      var currentRows = editedPhotos.find(a => a.id === id).rows
      if (operator == '+') {
        editedPhotos.find(a => a.id === id).rows = currentRows + 1 
      }
      else if (operator == '-') {
        editedPhotos.find(a => a.id === id).rows = currentRows - 1 
      }
    }
    this.setState({photos: editedPhotos})
  }

  updateEl(id) {
    console.log('updating', id)
    axios.put(`/api/v1/posts/${id}`, this.state.photos.find(el => el.id === id))
    .then(res => {
      console.log('done updating', res)
      document.location.reload();
    })
    .catch(err => console.log(err))  
  }

  updateGrid(){


/*     let result = this.state.photos.reduce((c, v) => {
      c[v.id] = c[v.id] || {};                        
      c[v.id]['position'] = v.position || null; 
      c[v.id]['rows'] = v.rows || null;   
      c[v.id]['cols'] = v.cols || null;   
      return c;
    }, {}); */

    let result = this.state.photos.map(el => ({
      id: el.id,
      cols: el.cols, 
      rows: el.rows,
      position: el.position
    }))

    console.log('new array', result);
    const json = JSON.stringify(result);
    const formData = new FormData();
    formData.append('gridchanges', result)
    console.log('formdata', formData );
      fetch('/api/v1/update_grid/' + json, {
      method: 'POST',
      body: formData
    })    
  }
/*     
    const formData = new FormData();
    formData.append('title', this.state.title);
    formData.append('body', this.state.body);
    formData.append('image', this.state.image);    
 */

/*     fetch('/api/v1/posts', {
      method: 'POST',
      body: formData
    })     */

  render() {
/*     const breakpointColumnsObj = {
      default: 5,
      1400: 5,
      1100: 4,
      900: 3,
      700: 2,
      500: 1
    };     */

/*     const useStyles = makeStyles({
      root: {
        width: 500,
        height: 450,
      },
    });
    const classes = useStyles();  */   


    console.log('my props', this.props)
    return (
          <div className="Photos-Container">
{/*             {
              this.state.loading ? 
                <span> LOADING </span> :
                <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column">                
                
                  { this.state.photos.map((el, index) => {
                    return (
                        el.image ? 
                          <div className={`item ${(index/3 === 1) ? "narrow" : "" }`}>
                            <img src={el.image.url}/> 
                            {this.props.editable ? 
                              <button className="Delete-button"onClick={ () => {this.deletePic(el.id)}}>DELETE</button>                              
                              : 
                              null  
                            }
                          </div>
                          : null 
                    )
                  })}
                </Masonry>
            } */}
      <ImageList
            variant="quilted"
            cols={5}
            
            className={this.props.classes.root}
      >
      { this.state.photos.map((el, index) => {
        return (
          <ImageListItem
          cols={el.cols || 1}
          rows={el.rows || 1}
          >
              <img src={el.image.url}
                srcSet={srcset(el.image.url, 121, el.rows, el.cols)}
              />
              {this.props.editable ? 
              <div className="edit-buttons">
              <span> COLS: </span>
              <button onClick={() => {this.editCol(el.id, '+', 'cols')}}>+</button>
              <button onClick={() => {this.editCol(el.id, '-', 'cols')}}>-</button>
              <span> ROWS: </span>
              <button onClick={() => {this.editCol(el.id, '+', 'rows')}}>+</button>
              <button onClick={() => {this.editCol(el.id, '-', 'rows')}}>-</button>  
              <button onClick={() => {this.updateEl(el.id)}}> SUBMIT GRID CHANGES </button>            
              <button className="Delete-button"onClick={ () => {this.deletePic(el.id)}}>DELETE</button>
              <span>CURRENT ORDER {el.position}</span>   
              <button onClick={() => {this.editOrder(el.id, '+')}}>+</button>
              <button onClick={() => {this.editOrder(el.id, '-')}}>-</button>                         
              </div>
              : 
              null  
              }              
          </ImageListItem>
        )
      })        
    }
        </ImageList>     
        {this.props.editable ? 
          <button onClick={() => {this.updateGrid()}}> UPDATE GRID </button> : null
        }
        </div>        


    )
  }
}

export default withStyles(styles)(PhotosIndex);