import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios'
import PhotoUpload from './PhotoUpload';
import PhotosIndex from './PhotosIndex';
import './photosedit.scss';
class PhotosEdit extends Component {
    constructor(props) {
      super(props)
      this.state = {
        title: '',
        image: '',
        body: '',
        redirect: false,
        file: null
      }
    }
  
  
    
    handleSubmit = event => {
      event.preventDefault();
/*       const formData = new FormData();
      formData.append('title', this.state.title);
      formData.append('body', this.state.body);
      formData.append('image', this.state.image);    
      fetch('/api/v1/posts', {
        method: 'POST',
        body: formData
      })
      .then(response => {
        console.log('POST RESPONSE', response)
      })
      .catch(error=>console.log(error)); */
    }
  
    render() {
      return (
        this.state.redirect ? 
          <Redirect to='/'/>
        :  
        <div>
            <PhotosIndex editable />
        </div>
      )
    }
  }
  
  export default PhotosEdit