import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import './App.css';
import PhotosIndex from './components/PhotosIndex';
import PhotoUpload from './components/PhotoUpload';
import Header from './components/Header';
import PhotosEdit from './components/PhotosEdit';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Header/>
        </header>
        <Switch>
          <div className="Content">
            <Route exact path="/">
              <PhotosIndex />
            </Route>
            <Route path="/create">
              <PhotoUpload />
            </Route>
            <Route path="/edit">
              <PhotosEdit />
            </Route>            
          </div>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
