import React, { Component } from 'react'
import {
  Link
} from "react-router-dom";
import './header.scss';

class Header extends React.Component {
    render () {
      return (
        <header className="header">
          <ul className="">
            <li>
                <Link to="/">HOME</Link>
            </li>
          </ul>
        </header>


      )
    }
  }
  
  export default Header;